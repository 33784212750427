import React, { useEffect, useState } from "react";
import axios from "axios";

import bannerImage from "./../../../Assets/Images/CareerBanner.png";

function ProjectBanner({ id }) {
  const [bannaerData, setData] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://www.tzazapi.quadtheoryltd.com:446/api/Project/getProjectsById?id=${id}`
      )
      .then((response) => {
        setData(response.data);
      });
  }, []);
  return (
    <div>
      <div>
        <div>
          {bannaerData.map((data) => {
            return (
              <div
                className="container-fluid sectionContainer sectionMergin"
                key={data.id}
              >
                <div className="row d-flex align-items-center ">
                  <div className="col-md-6">
                    <div>
                      <div className=" bannerTextAlignment  text-uppercase ">
                        <h1>{data.projectHeader}</h1>
                      </div>

                      <div className="text-uppercase ps-0 bannerTextAlignment ">
                        <p>{data.projectDescription}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="  d-flex justify-content-start">
                        <a href={data.projectLink} className=" button fs-5">
                          Contact us
                        </a>
                      </div>
                      <div className="  d-flex justify-content-end">
                        <a href={data.projectLink} className=" button fs-6">
                          VISIT
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="bannerImage">
                      <img src={bannerImage} alt=".." className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ProjectBanner;
