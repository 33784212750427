import React, { useEffect, useState } from "react";
import axios from "axios";

function ProjectSugestation() {
  const [projectData, setData] = useState([]);
  useEffect(() => {
    axios
      .get("https://www.tzazapi.quadtheoryltd.com:446/api/Project/getProjects")
      .then((response) => {
        setData(response.data);
      });
  }, []);
  return (
    <div>
      <div>
        <div>
          <div className="sectionMergin d-flex justify-content-center">
            <div className="container-fluid sectionContainer">
              <div className="row p-4">
                <div className="col-md-12 ">
                  <p className=" fs-2 fw-semibold">More Projects</p>
                </div>
              </div>
              <div className="row">
                {projectData.map((data) => {
                  return (
                    <div className="col-md-4 mb-4" key={data.id}>
                      <div className="">
                        <div className="card projectCard themeColour">
                          <img
                            src={data.image}
                            className="card-img-top"
                            alt="Service Image"
                          />
                          <div className="card-body">
                            <h3 className="card-title">{data.projectName}</h3>
                            <p className="card-text">
                              {data.projectDescription}
                            </p>
                            <a href={data.projectLink} className=" readMore">
                              Explore More
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectSugestation;
