import React, { useEffect, useState } from "react";
import "./AllServiceList.css";
import ServiceImg from "./../../../Assets/Images/BusinessAnalysis.jpg";
import dbImg from "./../../../Assets/Images/Business intelligence.jpg";
import appImg from "./../../../Assets/Images/COMMUNICATIONS TECHNOLOGY.jpg";
import erpImg from "./../../../Assets/Images/Consulting.jpg";
import dataImg from "./../../../Assets/Images/Data analytics.jpg";
import infoImg from "./../../../Assets/Images/Datainformation management.jpg";
import developmentImg from "./../../../Assets/Images/Development.jpg";
import softwareImg from "./../../../Assets/Images/SoftWareArchitecture development.jpg";
import archImg from "./../../../Assets/Images/Security technology development.jpg";
import deveImg from "./../../../Assets/Images/Systems integration.jpg";
import webDevImg from "./../../../Assets/Images/Web_development.jpg";
import axios from "axios";
import { Link } from "react-router-dom";
import Location from "../../HomePage/Location/Location";

function AllServiceList(props) {
  const [serviceData, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    axios
      .get(
        "https://www.tzazapi.quadtheoryltd.com:446/api/Service/getAllService"
      )
      .then((response) => {
        setData(response.data);
        setLoading(false);
      });
  }, []);
  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <img src={require("./../../../Assets/Images/Loader.gif")} />
      </div>
    );
  } else {
    return (
      // <div>
      //   <div className="container">
      //     <div className="row">
      //       <div className="col-12">
      //         <h2 className="text-bold fs-2 text-center">Our Services</h2>
      //       </div>
      //     </div>
      //     <div className="row">
      //       {serviceData.length > 0
      //         ? serviceData.map((d, i) => {
      //             return d.serviceLogo == "Na" ? (
      //               <div className="col-md-6 col-lg-4 mt-2" data-aos="fade-up">
      //                 <div className="card serviceCard h-100  card-custom-product">
      //                   <div className="">
      //                     <img
      //                       src={require(`./../../../Assets/Images/${d.serviceImage}`)}
      //                       className="card-img-top img-product"
      //                       alt={d.serviceImage}
      //                     />
      //                   </div>

      //                   <div className="card-body ">
      //                     {d.slug != undefined ? (
      //                       <Link
      //                         to={"/" + d.slug}
      //                         className="serviceName"
      //                         // reloadDocument={true}
      //                       >
      //                         <p className="card-title fs-4 fw-semibold text-uppercase ">
      //                           {d.serviceName}
      //                         </p>
      //                       </Link>
      //                     ) : (
      //                       <p
      //                         className="card-title fs-4 fw-semibold text-uppercase "
      //                         style={{ color: "#0b4040" }}
      //                       >
      //                         {d.serviceName}
      //                       </p>
      //                     )}

      //                     <p className="card-text text-uppercase ">
      //                       <span className=" me-3 cardText ">
      //                         {" "}
      //                         {d.serviceDescription}
      //                       </span>
      //                     </p>
      //                   </div>
      //                   <div className="text-end">
      //                     {d.slug != undefined ? (
      //                       <Link
      //                         to={"/" + d.slug}
      //                         className="serviceName"
      //                         // reloadDocument={true}
      //                       >
      //                         See More{" "}
      //                         <img
      //                           src={require("./../../../Assets/Images/right-arrow.png")}
      //                           alt=""
      //                           style={{ width: "1rem" }}
      //                         />
      //                       </Link>
      //                     ) : (
      //                       ""
      //                     )}
      //                   </div>
      //                 </div>
      //               </div>
      //             ) : (
      //               ""
      //             );
      //           })
      //         : "Loading"}
      //       {/* <Location /> */}
      //     </div>
      //   </div>
      // </div>
      <div className="pb-4" style={{ backgroundColor: "#eef3ff" }}>
        <div className="container">
          <div className="row">
           
              <h1 className="tittleText">Our Services</h1>
            
          </div>
          <div className="serviceContainer">
            {serviceData.length > 0
              ? serviceData.map((d, i) => {
                  return (
                    <div className="" data-aos="fade-up">
                      <div className="card serviceCard h-100 card-custom-product full-width-image-services">
                        <div className="">
                          <img
                            src={require(`./../../../Assets/Images/${d.serviceLogo}`)}
                            // src={require(`./../../../Assets/Images/mobile_development.png`)}
                            className="card-img-top img-product"
                            alt={d.serviceImage}
                          />
                        </div>

                        <div className="card-body ">
                          {d.slug != undefined ? (
                            <Link
                              to={"/" + d.slug}
                              className="serviceName"
                              // reloadDocument={true}
                            >
                              <p className="card-title  fw-bold">
                                {d.serviceName}
                              </p>
                            </Link>
                          ) : (
                            <p
                              // className="card-title fs-2 fw-semibold text-uppercase "
                              className="card-title  fw-bold "
                              style={{ color: "#0b4040" }}
                            >
                              {d.serviceName}
                            </p>
                          )}

                          <p
                            // className="card-text text-uppercase "
                            className="card-text "
                          >
                            <span className=" me-3 cardText ">
                              {" "}
                              {d.serviceDescription}
                            </span>
                          </p>
                        </div>
                        <div className="text-start ms-3">
                          {d.slug != undefined ? (
                            <Link
                              to={"/" + d.slug}
                              className="serviceName"
                              // reloadDocument={true}
                            >
                              See More{" "}
                              <img
                                src={require("./../../../Assets/Images/right-arrow.png")}
                                alt=""
                                style={{ width: "1rem" }}
                              />
                            </Link>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })
              : "Loading"}
            {/* <Location /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default AllServiceList;
